import { render, staticRenderFns } from "./entryMatters.vue?vue&type=template&id=1c244e86&scoped=true&"
import script from "./entryMatters.vue?vue&type=script&lang=js&"
export * from "./entryMatters.vue?vue&type=script&lang=js&"
import style0 from "./entryMatters.vue?vue&type=style&index=0&id=1c244e86&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c244e86",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/jenkins_home/workspace/HRSAAS-WEB-DBSJ-DEV/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c244e86')) {
      api.createRecord('1c244e86', component.options)
    } else {
      api.reload('1c244e86', component.options)
    }
    module.hot.accept("./entryMatters.vue?vue&type=template&id=1c244e86&scoped=true&", function () {
      api.rerender('1c244e86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/user/Induction/form/entryMatters.vue"
export default component.exports