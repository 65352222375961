var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        "overflow-auto": "",
        closable: true,
        visible: _vm.visible,
        title: _vm.modalTitle,
        size: "normal",
        "z-index": 2,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-tabs",
        {
          staticClass: "tabs_model",
          on: { change: _vm.callback },
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        _vm._l(_vm.entryMatterList, function (item, index) {
          return _c(
            "a-tab-pane",
            { key: index, attrs: { tab: item.itemName } },
            [
              _c(
                "div",
                { staticClass: "tabs_box" },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "addInfo",
                      refInFor: true,
                      attrs: {
                        "label-col": _vm.labelCol,
                        "wrapper-col": _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              item.itemKey === "entry_attence"
                                ? _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        label: "考勤组名称",
                                        prop: "entryAttence",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.funName
                                                .addStaffInAttendanceGroup ===
                                              "addStaffInAttendanceGroup",
                                            "allow-clear": "",
                                            placeholder: "请选择考勤组",
                                          },
                                          on: { change: _vm.changeAttenceName },
                                          model: {
                                            value:
                                              _vm.entryAttence
                                                .entryAttenceGroup,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.entryAttence,
                                                "entryAttenceGroup",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "entryAttence.entryAttenceGroup",
                                          },
                                        },
                                        _vm._l(
                                          _vm.entryAttenceGroupList,
                                          function (it) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: it.attendanceGroupId,
                                                attrs: {
                                                  value: it.attendanceGroupId,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(it.attendanceGroupName)
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.itemKey === "entry_salary"
                                ? _c(
                                    "div",
                                    _vm._l(_vm.entrySalaryList, function (i) {
                                      return _c(
                                        "a-form-model-item",
                                        {
                                          key: i.fieldCode,
                                          attrs: {
                                            label: i.fieldName,
                                            prop: i.fieldCode,
                                          },
                                        },
                                        [
                                          _c("a-input", {
                                            attrs: {
                                              placeholder:
                                                "请填写" + i.fieldName,
                                            },
                                            model: {
                                              value: i.amount,
                                              callback: function ($$v) {
                                                _vm.$set(i, "amount", $$v)
                                              },
                                              expression: "i.amount",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              item.itemKey === "salary_group_config"
                                ? _c(
                                    "a-form-model-item",
                                    {
                                      attrs: { label: "薪资组名称", prop: "" },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.funName.setStaffGroup ===
                                              "setStaffGroup",
                                            "allow-clear": "",
                                            placeholder: "请选择薪资组",
                                          },
                                          on: { change: _vm.changeSalary },
                                          model: {
                                            value:
                                              _vm.salaryGroupConfig
                                                .salaryGroupName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.salaryGroupConfig,
                                                "salaryGroupName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "salaryGroupConfig.salaryGroupName",
                                          },
                                        },
                                        _vm._l(
                                          _vm.salaryGroupList,
                                          function (it) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: it.groupId,
                                                attrs: { value: it.groupId },
                                              },
                                              [_vm._v(_vm._s(it.groupName))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.itemKey === "entry_taxpay_subject"
                                ? _c(
                                    "a-form-model-item",
                                    {
                                      attrs: {
                                        "allow-clear": "",
                                        label: "报税主体",
                                        prop: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            disabled:
                                              _vm.funName
                                                .addTaxpaySubjectStaff ===
                                              "addTaxpaySubjectStaff",
                                            placeholder: "请选择报税主体",
                                          },
                                          on: { change: _vm.changeTaxEntity },
                                          model: {
                                            value:
                                              _vm.entryTaxpaySubject.taxEntity,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.entryTaxpaySubject,
                                                "taxEntity",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "entryTaxpaySubject.taxEntity",
                                          },
                                        },
                                        _vm._l(
                                          _vm.endyearBounsList,
                                          function (it) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: it.taxpaySubjectId,
                                                attrs: {
                                                  value: it.taxpaySubjectId,
                                                },
                                              },
                                              [_vm._v(_vm._s(it.tenantName))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.itemKey === "entry_insurance"
                                ? _c("EntryInsurance", {
                                    ref: "entryInsurance",
                                    refInFor: true,
                                    attrs: {
                                      "insure-company-list":
                                        _vm.insureCompanyList,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          )
        }),
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "footer_box" },
          [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
              [_vm._v("提交")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }